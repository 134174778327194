import React, { useMemo } from 'react'
import config from 'configs'
import { makeStyles } from '@material-ui/core/styles'
import { styles } from './styles'
import {
  Grid,
  Typography,
  Box,
  useMediaQuery,
  Theme,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button
} from '@material-ui/core'
import { useSpaceCustomization } from 'hooks/useSpaceCustomization'
import app from 'configs/app.json'
import { Call, Email, HelpOutline } from '@material-ui/icons'

const useStyles = makeStyles(styles)

interface IProps {}

const ContactPointDialog: React.FC<{
  contactPoints: (
    | { name: string; phone: string }
    | { name: string; email: string }
    | { name: string; zaloURL: string }
  )[]
}> = ({ contactPoints }) => {
  const [open, setOpen] = React.useState(false)

  const classes = useStyles()

  return (
    <>
      <Box
        position={'fixed'}
        className={classes.floatingHelpButtonWrapper}
        bottom={0}
        right={0}
      >
        <Button
          style={{ borderRadius: 9999, padding: 8 }}
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          <Box display={'flex'} alignItems={'center'} gridGap={2}>
            <HelpOutline />
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              HỖ TRỢ
            </Typography>
          </Box>
        </Button>
      </Box>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="dialog-finish-title"
        aria-describedby="dialog-finish-description"
      >
        <DialogTitle style={{ paddingBottom: 0 }} id="dialog-finish-title">
          Liên hệ hỗ trợ
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="dialog-finish-description"
            style={{ color: '#000' }}
          >
            <p>
              Anh/Chị vui lòng liên hệ qua các kênh dưới đây để được hỗ trợ
              trong quá trình học tập trên hệ thống Agilearn:
            </p>
            {contactPoints.map((contactPoint) => {
              if ('phone' in contactPoint) {
                return (
                  <Box display={'flex'} alignItems={'center'} gridGap={1}>
                    <Call />
                    <p>
                      ({contactPoint.name}):{' '}
                      <a
                        href={`tel:${contactPoint.phone}`}
                        style={{ textDecoration: 'none' }}
                      >
                        {contactPoint.phone}
                      </a>
                    </p>
                  </Box>
                )
              }

              if ('email' in contactPoint) {
                return (
                  <Box display={'flex'} alignItems={'center'} gridGap={1}>
                    <Email />
                    <p>
                      ({contactPoint.name}):{' '}
                      <a
                        href={`mailto:${contactPoint.email}`}
                        style={{ textDecoration: 'none' }}
                      >
                        {contactPoint.email}
                      </a>
                    </p>
                  </Box>
                )
              }

              if ('zaloURL' in contactPoint) {
                return (
                  <Box display={'flex'} alignItems={'center'} gridGap={1}>
                    <Email />
                    <p>
                      ({contactPoint.name}):{' '}
                      <a
                        href={`${contactPoint.zaloURL}`}
                        style={{ textDecoration: 'none' }}
                      >
                        {contactPoint.zaloURL}
                      </a>
                    </p>
                  </Box>
                )
              }
            })}

            <p>Trân trọng cảm ơn Anh/Chị!</p>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

const Footer: React.FC<IProps> = () => {
  const classes = useStyles(() => {})

  const isDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const spaceCustomize = useSpaceCustomization()

  const showBugReportDialog = () => {
    // @ts-ignore
    window.showCollectorDialog()
  }

  const contactPoints = useMemo<
    (
      | {
          name: string
          phone: string
        }
      | { email: string; name: string }
    )[]
  >(() => spaceCustomize?.contactPoints || app.contactPoints, [spaceCustomize])

  if (spaceCustomize?.hideFooter) return null

  if (isDownSm) {
    return (
      <>
        <div className={classes.footer}>
          <Grid container spacing={3}>
            {!spaceCustomize?.hideFooterTermsOfService && (
              <Grid item xs={6}>
                <Box textAlign="center">
                  <Link target="_blank" rel="noopener noreferrer" href={'/'}>
                    Điều khoản dịch vụ
                  </Link>
                </Box>
              </Grid>
            )}

            {!spaceCustomize?.hideFooterPrivacyPolicy && (
              <Grid item xs={6}>
                <Box textAlign="center">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={config.app.privacyPolicy}
                  >
                    Chính sách bảo mật
                  </Link>
                </Box>
              </Grid>
            )}
            {!spaceCustomize?.hideFooterChangeLogs && (
              <Grid item xs={6}>
                <Box textAlign="center">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={'/change-logs'}
                  >
                    Nhật ký thay đổi
                  </Link>
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} sm={12} className={classes.siteInfo}>
            <Box textAlign="center" mt={2}>
              <Typography variant="body2">
                <strong>
                  {config.app.siteName}
                  {config.app.siteSlogan && (
                    <span> - {config.app.siteSlogan}</span>
                  )}
                </strong>
              </Typography>
              <em>
                {`Copyright©${new Date().getFullYear()} - ` +
                  config.app.companyName}
              </em>
            </Box>
          </Grid>
          <Box>
            <ContactPointDialog contactPoints={contactPoints} />
          </Box>
        </div>
      </>
    )
  }

  return (
    <div className={classes.footer}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} className={classes.siteInfo}>
          <Typography variant="body2">
            <strong>
              {config.app.siteName}
              {config.app.siteSlogan && <span> - {config.app.siteSlogan}</span>}
            </strong>
          </Typography>
          <em>
            {`Copyright©${new Date().getFullYear()} - ` +
              config.app.companyName}
          </em>
        </Grid>
        {/* <Grid item xs={12} sm={6} className={classes.mobileApp}>
          <Typography variant="body2">
            <strong>Tải ứng dụng trên điện thoại</strong>
          </Typography>
          <Box mt={1}>
            <a href={config.app.appGooglePlayUrl}>
              <img src={images.GOOGLE_PLAY} alt="Google Play" />
            </a>
            <a href={config.app.appAppStoreUrl}>
              <img src={images.APPLE_STORE} alt="Apple Store" />
            </a>
          </Box>
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <Box display={'flex'} justifyContent={'flex-end'}>
            {!spaceCustomize?.hideFooterTermsOfService && (
              <Box mr={3}>
                <Link target="_blank" rel="noopener noreferrer" href={'/'}>
                  Điều khoản dịch vụ
                </Link>
              </Box>
            )}
            {!spaceCustomize?.hideFooterPrivacyPolicy && (
              <Box mr={3}>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={config.app.privacyPolicy}
                >
                  Chính sách bảo mật
                </Link>
              </Box>
            )}
            {!spaceCustomize?.hideFooterChangeLogs && (
              <Box mr={3}>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={'/change-logs'}
                >
                  Nhật ký thay đổi
                </Link>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Box>
        <ContactPointDialog contactPoints={contactPoints} />
      </Box>
    </div>
  )
}

export default Footer
