import { Theme, createStyles } from '@material-ui/core/styles'

export const styles = (theme: Theme) =>
  createStyles({
    footer: {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body2.fontSize,
      borderTop: '1px solid ' + theme.palette.divider,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      // marginTop: theme.spacing(3),
      height: theme.footer.height
    },

    siteInfo: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center'
      }
    },

    mobileApp: {
      // display: 'flex',
      display: 'none',
      alignItems: 'flex-end',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        alignItems: 'center'
      },

      '& img': {
        height: theme.spacing(5),
        marginLeft: theme.spacing(2)
      }
    },
    noUnderlineLink: {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.h6.fontWeight
    },
    floatingHelpButtonWrapper: {
      right: theme.spacing(3),
      bottom: theme.spacing(3),

      [theme.breakpoints.down('sm')]: {
        right: theme.spacing(2),
        bottom: theme.spacing(2)
      }
    }
  })
