import { useLearningSpaceContext } from 'contexts/SubdomainContext'
import { useDocument, useDocumentDataOne } from 'hooks'

import { useFirestore } from 'reactfire'

declare type SpaceCustomization = Partial<{
  backgroundURL: string
  logoURL: string
  lightLogoURL: string
  hideSignInWithProviders: boolean
  hideFooter: boolean
  hideCreateAccount: boolean
  hideFooterTermsOfService: boolean
  hideFooterPrivacyPolicy: boolean
  hideFooterChangeLogs: boolean
  hideStudentOrderList: boolean
  hideStudentClassList: boolean
  contactPoints: (
    | {
        name: string
        phone: string
      }
    | { email: string; name: string }
  )[]
  disableSendCongratsEmail: boolean
}>

export const useSpaceCustomization = (): SpaceCustomization | null => {
  const { domain } = useLearningSpaceContext()

  const db = useFirestore()

  const data = useDocumentDataOne<SpaceCustomization>(
    db.collection('space_customization').doc(domain || '0')
  )

  return data || null
}
