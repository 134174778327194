import BlankLayout from 'layouts/BlankLayout'
import React from 'react'
import { styles } from './styles'
import { Container, Paper, makeStyles } from '@material-ui/core'
import images from 'assets/images/index'
import Configs from 'configs'
import { useSpaceCustomization } from 'hooks/useSpaceCustomization'

const useStyles = makeStyles(styles)

interface IProps {}

const AuthenticationLayout: React.FC<IProps> = (props) => {
  const { children } = props
  const classes = useStyles(() => {})

  const theme = useSpaceCustomization()

  return (
    <BlankLayout>
      <div className={classes.root}>
        <Container maxWidth="sm">
          <div className={classes.logo}>
            <img
              src={theme?.logoURL || images.LOGO}
              alt={Configs.app.siteName}
            />
          </div>

          <Paper className={classes.body}>{children}</Paper>
        </Container>
      </div>
    </BlankLayout>
  )
}

export default AuthenticationLayout
