import { Theme, createStyles } from '@material-ui/core/styles'

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(10),
      minHeight: '75vh'
    },

    logo: {
      margin: '0 auto',
      textAlign: 'center',
      marginBottom: theme.spacing(5),
      '& > img': {
        width: '50%'
      }
    },

    body: {
      padding: theme.spacing(3)
    },

    formTitle: {
      paddingBottom: theme.spacing(3)
    }
  })
