import {
  Box,
  CardContent,
  Container,
  Grid,
  Typography,
  Paper,
  TextField,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import firebase from 'firebase'
import { useDocumentData, useFunctions } from 'hooks'
import React, { useCallback, useEffect, useState } from 'react'
import { useFirestore, useUser } from 'reactfire'
import { UserMetadataType } from 'types'
import {
  Email,
  Lock,
  Person,
  VisibilityOff,
  Visibility
} from '@material-ui/icons'
import LoadableButton from 'components/LoadableButton'
import { useSnackbar } from 'contexts/SnackbarContext'
import { isEmpty } from 'lodash'

interface IProps {}

const CompleteUserInformation: React.FC<IProps> = ({ children }) => {
  const user = useUser<firebase.User>()
  const db = useFirestore()
  const showToast = useSnackbar()
  const [name, setName] = useState(user.displayName || '')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [saving, setSaving] = useState(false)
  const [userSyncedToSSO, setUserSyncedToSSO] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const functions = useFunctions()
  const onChangeName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value),
    []
  )
  const onChangePassword = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setPassword(event.target.value),
    []
  )

  const onChangeConfirmPassword = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setConfirmPassword(event.target.value),
    []
  )

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const metadataRef = db.collection('user_metadata').doc(user.uid)

  const userMetadata = useDocumentData<UserMetadataType>(metadataRef, {
    idField: 'id'
  })

  useEffect(() => {
    const loggedInWithEmail = localStorage.getItem('loggedInWithEmail')
    if (userMetadata) {
      const notSyncedSSOAccount =
        userMetadata.syncedSSOAccount === undefined ||
        !userMetadata.syncedSSOAccount

      if (loggedInWithEmail === 'true' && notSyncedSSOAccount) {
        setUserSyncedToSSO(false)
      } else {
        setUserSyncedToSSO(true)
      }
    }
  }, [userMetadata])

  const onSave = useCallback(
    async (event: React.FormEvent<any>) => {
      try {
        event.preventDefault()
        setSaving(true)
        const $errs = validateForm({ name: name, password: password })
        setErrors($errs)
        if (isEmpty($errs)) {
          const callable = functions.httpsCallable(
            'callable-authUpdateUserInfo'
          )
          await callable({ displayName: name, password: password })
          await metadataRef.update({
            isNewUser: false,
            syncedSSOAccount: true,
            name: name,
            email: user.email
          })
          setUserSyncedToSSO(true)
          setSaving(false)
        }
      } catch (e) {
        console.error(e)
        showToast('Có lỗi xảy ra!', 'error')
        setSaving(false)
      }
    },
    [functions, metadataRef, name, password, showToast, user.email]
  )

  const handleSyncAccount = useCallback(
    async (event: React.FormEvent<any>) => {
      try {
        event.preventDefault()
        setSaving(true)
        const $errs = validatePassword(password, confirmPassword)
        setErrors($errs)
        if (isEmpty($errs)) {
          const callable = functions.httpsCallable(
            'callable-authUpdateUserInfo'
          )
          await callable({ password: password })
          setUserSyncedToSSO(true)
          await metadataRef.set(
            {
              syncedSSOAccount: true
            },
            { merge: true }
          )
          localStorage.removeItem('loggedInWithEmail')
        }
      } catch (e) {
        console.error(e)
        showToast('Có lỗi xảy ra!', 'error')
      }
      setSaving(false)
    },
    [confirmPassword, functions, metadataRef, password, showToast]
  )

  const isNewUser = userMetadata?.isNewUser || true
  useEffect(() => {
    if (isNewUser) {
      console.debug('Update metadata')
      metadataRef.set(
        {
          isNewUser: false,
          syncedSSOAccount: true,
          name: name,
          email: user.email
        },
        { merge: true }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewUser])

  // if (!userMetadata || userMetadata.isNewUser) {
  //   return (
  //     <>
  //       <Container maxWidth={'sm'}>
  //         <Box mt={5}>
  //           <Paper>
  //             <CardContent>
  //               <Box mb={4}>
  //                 <Typography align={'center'} variant={'h6'}>
  //                   Hoàn tất thông tin
  //                 </Typography>
  //               </Box>
  //               <form style={{ paddingBottom: 20 }} onSubmit={onSave}>
  //                 <Grid container spacing={2}>
  //                   <Grid item xs={12}>
  //                     <Box display={'flex'} justifyContent={'center'}>
  //                       <Grid item xs={12} md={8}>
  //                         <TextField
  //                           InputProps={{
  //                             startAdornment: (
  //                               <InputAdornment position="start">
  //                                 <Email />
  //                               </InputAdornment>
  //                             )
  //                           }}
  //                           value={user.email}
  //                           disabled
  //                           variant={'outlined'}
  //                           fullWidth
  //                           size={'small'}
  //                         />
  //                       </Grid>
  //                     </Box>
  //                   </Grid>
  //                   <Grid item xs={12}>
  //                     <Box display={'flex'} justifyContent={'center'}>
  //                       <Grid item xs={12} md={8}>
  //                         <TextField
  //                           helperText={errors.name}
  //                           error={Boolean(errors.name)}
  //                           InputProps={{
  //                             startAdornment: (
  //                               <InputAdornment position="start">
  //                                 <Person />
  //                               </InputAdornment>
  //                             )
  //                           }}
  //                           value={name}
  //                           onChange={onChangeName}
  //                           placeholder={'Tên hiển thị'}
  //                           variant={'outlined'}
  //                           fullWidth
  //                           size={'small'}
  //                         />
  //                       </Grid>
  //                     </Box>
  //                   </Grid>
  //                   <Grid item xs={12}>
  //                     <Box display={'flex'} justifyContent={'center'}>
  //                       <Grid item xs={12} md={8}>
  //                         <TextField
  //                           helperText={errors.password}
  //                           error={Boolean(errors.password)}
  //                           InputProps={{
  //                             startAdornment: (
  //                               <InputAdornment position="start">
  //                                 <Lock />
  //                               </InputAdornment>
  //                             )
  //                           }}
  //                           type={'password'}
  //                           value={password}
  //                           onChange={onChangePassword}
  //                           placeholder={'Mật khẩu'}
  //                           variant={'outlined'}
  //                           fullWidth
  //                           size={'small'}
  //                         />
  //                       </Grid>
  //                     </Box>
  //                   </Grid>
  //                   <Grid item xs={12}>
  //                     <Box display={'flex'} justifyContent={'center'}>
  //                       <Grid item xs={12} md={8}>
  //                         <LoadableButton
  //                           type={'submit'}
  //                           loading={saving}
  //                           color={'primary'}
  //                           fullWidth
  //                           variant={'contained'}
  //                         >
  //                           Hoàn tất
  //                         </LoadableButton>
  //                       </Grid>
  //                     </Box>
  //                   </Grid>
  //                 </Grid>
  //               </form>
  //             </CardContent>
  //           </Paper>
  //         </Box>
  //       </Container>
  //     </>
  //   )
  // }

  if (!userSyncedToSSO) {
    return (
      <>
        <Container maxWidth={'sm'}>
          <Box mt={5}>
            <Paper>
              <CardContent>
                <Box mb={3}>
                  <Typography align={'center'} variant={'h6'}>
                    Vì lý do bảo mật bạn vui lòng đặt mật khẩu mới.
                  </Typography>
                </Box>
                <form
                  style={{ paddingBottom: 20 }}
                  onSubmit={handleSyncAccount}
                >
                  <Grid item xs={12}>
                    <Box display={'flex'} justifyContent={'center'} mb={2}>
                      <Grid item xs={12} md={8}>
                        <TextField
                          helperText={errors.password}
                          error={Boolean(errors.password)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Lock />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={toggleShowPassword}>
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          onChange={onChangePassword}
                          placeholder={'*Nhập mật khẩu mới'}
                          variant={'outlined'}
                          fullWidth
                          size={'small'}
                        />
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display={'flex'} justifyContent={'center'}>
                      <Grid item xs={12} md={8}>
                        <TextField
                          helperText={errors.confirmPassword}
                          error={Boolean(errors.confirmPassword)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Lock />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={toggleShowConfirmPassword}>
                                  {showConfirmPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          type={showConfirmPassword ? 'text' : 'password'}
                          value={confirmPassword}
                          onChange={onChangeConfirmPassword}
                          placeholder={'*Nhập lại mật khẩu'}
                          variant={'outlined'}
                          fullWidth
                          size={'small'}
                        />
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display={'flex'} justifyContent={'center'} mt={3}>
                      <Grid item xs={12} md={8}>
                        <LoadableButton
                          type={'submit'}
                          loading={saving}
                          color={'primary'}
                          fullWidth
                          variant={'contained'}
                        >
                          Xác nhận
                        </LoadableButton>
                      </Grid>
                    </Box>
                  </Grid>
                </form>
              </CardContent>
            </Paper>
          </Box>
        </Container>
      </>
    )
  }

  return <>{children}</>
}

export default CompleteUserInformation

function validateForm(data: { name?: string; password?: string }) {
  const errors: { [key: string]: string } = {}

  if (!data.name) {
    errors.name = 'Tên hiển thị không được bỏ trống!'
  }
  if (!data.password) {
    errors.password = 'Mật khẩu không được bỏ trống!'
  } else if (data.password.trim().length < 8) {
    errors.password = 'Mật khẩu không được ít hơn 8 ký tự!'
  }

  return errors
}

function validatePassword(password: string, confirmPassword: string) {
  const errors: { [key: string]: string } = {}

  if (!password) {
    errors.password = 'Mật khẩu không được bỏ trống!'
  } else if (password.trim().length < 8) {
    errors.password = 'Mật khẩu không được ít hơn 8 ký tự!'
  }

  if (!confirmPassword) {
    errors.confirmPassword = 'Bạn phải nhập lại mật khẩu'
  }

  if (password !== confirmPassword) {
    errors.confirmPassword = 'Mật khẩu nhập lại không khớp'
  }

  return errors
}
